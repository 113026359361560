var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('icon-class-bar',{attrs:{"title":"Semesternoten","marks":true,"comments":true,"presence":true,"edit_marks":true,"current":"semester-marks"},on:{"edit-mode":function($event){_vm.editMode=true}}}),_c('v-card-text',[_c('v-row',{staticClass:"ml-2",attrs:{"align":"start"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"11","sm":"4"}},[_c('v-select',{staticClass:"mt-3",attrs:{"items":_vm.classes,"filled":"","label":"Klasse","dense":"","outlined":"","color":"success"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"11","sm":"4"}},[_c('v-select',{staticClass:"mt-3",attrs:{"items":_vm.subjects,"filled":"","label":"Fach","dense":"","outlined":"","color":"success"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"11","sm":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.students,"item-key":"name","multi-sort":"","dense":""},scopedSlots:_vm._u([{key:"header.marks[0]",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)),_c('br'),_vm._v(" Datum: "+_vm._s(header.date)+" ")]}},{key:"header.marks[1]",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)),_c('br'),_vm._v(" Datum: "+_vm._s(header.date)+" ")]}},{key:"header.marks[2]",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)),_c('br'),_vm._v(" Datum: "+_vm._s(header.date)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"26","dark":""}},[_c('img',{attrs:{"src":item.img,"alt":""}})]),_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"item.marks[0]",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.marks[0])+" ")])]}},{key:"item.marks[1]",fn:function(ref){
var item = ref.item;
return [(!_vm.editMode)?_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.marks[1])+" ")]):_c('v-row',{staticClass:"my-1",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"3"}},[_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","dense":""},model:{value:(item.marks[1]),callback:function ($$v) {_vm.$set(item.marks, 1, $$v)},expression:"item.marks[1]"}})],1)],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"pl-2 pt-2",staticStyle:{"position":"absolute"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","color":"teal","text-color":"white"}},[_vm._v(" Durchschnitt: 4.5 ")])],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.editMode)?_c('div',{staticClass:"d-flex flex-wrap mt-4"},[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","color":"green","small":""},on:{"click":function($event){_vm.editMode=false}}},[_vm._v(" Speichern ")]),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","color":"blue","small":""}},[_vm._v(" Senden ")]),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","color":"red","small":""},on:{"click":function($event){_vm.editMode=false}}},[_vm._v(" Abbrechen ")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }